/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.confirm{
  :global(.tea-dialog__inner){
    padding:36px;
  }
  :global(.tea-dialog__header){
    text-align: center;
  }
  :global(.tea-dialog__headertitle){
    font-size: 20px;
    color:#333;
  }
  :global(.tea-dialog__body){
    font-size:14px;
    color:#333;
  }
  :global(.tea-btn){
    width:120px;
    height: 40px;
    font-size: 14px;
  }
  :global(.tea-btn--weak){
    margin-right:70px;
    color:#333;
  }
}
