/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.navs{
  margin-top: 30px;
  display: flex;
  border-bottom: 1px solid #eee;
  .nav{
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    border-bottom: 3px solid white;
    margin-right:20px;
    cursor: pointer;
    &.act{
      border-bottom: 3px solid #006eff;
    }
  }
}
.detail{
  width:100%;
  :global(.tea-form__item){
    width:50%;
    margin-right: 0;
  }
}
